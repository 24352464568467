import { PaletteOptions, createTheme, css } from "@mui/material/styles";

export type AllowedTheme = NonNullable<PaletteOptions["mode"]>;

export const DEFAULT_THEME: AllowedTheme = "light";

export const lightTheme = createTheme({
  palette: {
    primary: { main: "#9147FF" },
    secondary: { main: "#2a48f3" },
    mode: "light"
  }
});

export const darkTheme = createTheme({
  palette: {
    primary: { main: "#2a48f3" },
    secondary: { main: "#9147FF" },
    mode: "dark"
  }
});

export const globalStyles = css`
  :root {
    body {
      background-color: #fff;
      color: #121212;
    }
  }
  [class*="dark"] {
    body {
      background-color: #121212;
      color: #fff;
    }
  }
  [class*="light"] {
    body {
      background-color: #fff;
      color: #121212;
    }
  }
`;
